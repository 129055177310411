<template>
  <div style="padding: 20px">
    <el-button style="margin-bottom: 20px" @click="create" type="primary" size="small">新增</el-button>
    <el-table
        :data="tableData"
        border
        style="width: 100%">
      <el-table-column
          prop="job"
          label="岗位"
          width="180">
      </el-table-column>
      <el-table-column
          prop="lcoation"
          label="地区">
      </el-table-column>
      <el-table-column
          prop="deadline"
          label="截止日期">
      </el-table-column>
      <el-table-column
          prop="info"
          label="职位详情">
        <template slot-scope="scope">
          <div v-html="scope.row.info"></div>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="del(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <job-form ref="form" @save="save"></job-form>

  </div>
</template>

<script>
import JobForm from "@/components/admin/job/JobForm";
import {download, upload} from "@/api/api";
export default {
  name: "JobTable",
  components: {JobForm},
  props:['lang'],
  watch:{
    lang(){
      this.init();
    }
  },
  data(){
    return {
      tableData:[],
      type:'job'
    }
  },
  mounted() {
    this.init()
  },
  computed:{
    fileName(){
      return `${this.type}_${this.lang}.json`
    }
  },
  methods:{
    init(){
      download(this.fileName).then(res => {
        if(res.data.code !== 500 ) {
          this.tableData = res.data;
        }
      })
    },
    save(newData){
      this.loading = true;
      let oldIndex = -1
      this.tableData.forEach((item, index) => {
        if (item.id === newData.id) {
          oldIndex = index;
          Object.assign(item,newData);
        }
      })
      if (oldIndex === -1) {
        this.tableData.push(newData);
      }
     this.uploadJson()
    },
    create(){
      this.$refs.form.open({})
    },
    edit(row){
      this.$refs.form.open(row)
    },
    uploadJson(){
      const file = new File([JSON.stringify(this.tableData)], this.fileName);
      upload(file).then(res => {
        this.loading = false;
      })
    },
    del(row){
      const i = this.tableData.indexOf(row)
      this.tableData.splice(i,1)
      this.uploadJson()
    },
  }
}
</script>

<style scoped>

</style>
