<template>
  <el-dialog
      :title="data.id ? '编辑' : '新增' "
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      width="30%"
  >
    <el-form ref="proForm"  :mode="data" >
      <el-form-item label="头像">
        <el-upload class="upload-demo" action="https://www.bv-design.cn/api/upload" list-type="picture"
                   :on-success="logoUpload">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="详情">
        <simditor id="content"  v-model="data.content"></simditor>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="cancel">取 消</el-button>
    <el-button type="primary" @click="save">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import {getImgUrl} from "@/api/api";
import {guid} from "@/utils/common";
import Simditor from "../../../Simditor";

export default {
  name: "PartnerForm",
  components: {Simditor},
  data(){
    return {
      data:{},
      dialogVisible:false,
      contentEditor:null
    }
  },
  mounted() {
    this.initEditor();
  },
  methods:{
    initEditor(){

    },
    open(data){
      this.dialogVisible = true;
      this.data = data;
    },
    save(){
      if(!this.data.id) {
        this.data.id = guid();
      }
      this.$emit('save',this.data)
      this.dialogVisible = false;
    },
    cancel(){
      this.dialogVisible = false;
    },
    logoUpload(res, file){
      this.data.headImg = getImgUrl(file)
    }
  }
}
</script>

<style scoped>

</style>
