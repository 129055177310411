<template>
  <div>
    <el-form ref="proForm"  :mode="data" >
      <el-form-item label="公司-开屏大图">
        <el-upload class="upload-demo" action="https://www.bv-design.cn/api/upload" list-type="picture"
                   :on-success="bigImgUpload">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="公司-介绍">
        <simditor id="companyInfo"  v-model="data.companyInfo" style="margin-left: 80px;"></simditor>
      </el-form-item>
      <el-form-item label="公司-介绍右侧图片">
        <el-upload class="upload-demo" action="https://www.bv-design.cn/api/upload" list-type="picture"
                   :on-success="infoImgUpload">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="就业-介绍">
        <simditor id="jobInfo"  v-model="data.jobInfo" style="margin-left: 80px;"></simditor>
      </el-form-item>
      <el-form-item label="就业-介绍左侧图片">
        <el-upload class="upload-demo" action="https://www.bv-design.cn/api/upload" list-type="picture"
                   :on-success="jobLeftImgUpload">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="就业-介绍右侧图片">
        <el-upload class="upload-demo" action="https://www.bv-design.cn/api/upload" list-type="picture"
                   :on-success="jobRightImgUpload">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="save">确 定</el-button>
  </span>
  </div>
</template>

<script>
import {download, getImgUrl, upload} from "@/api/api";
import Simditor from "../Simditor";

export default {
  name: "CommonForm",
  components: {Simditor},
  props:['lang'],
  data(){
    return {
      data:{},
      contentEditor:null,
      type:'common'
    }
  },
  mounted() {
    this.init();
  },
  computed:{
    fileName(){
      return `${this.type}_${this.lang}.json`
    }
  },
  methods:{
    init(){
      download(this.fileName).then(res => {
        if(res.data.code !== 500 ) {
          this.data = res.data;
        }
      })
    },
    save(){
      this.uploadJson();
    },
    uploadJson(){
      const file = new File([JSON.stringify(this.data)], this.fileName);
      upload(file).then(res => {
        this.loading = false;
      })
    },
    bigImgUpload(res, file){
      this.data.bigImg = getImgUrl(file)
    },
    infoImgUpload(res, file){
      this.data.infoImg = getImgUrl(file)
    },
    jobLeftImgUpload(res, file){
      this.data.leftImg = getImgUrl(file)
    },
    jobRightImgUpload(res, file){
      this.data.rightImg = getImgUrl(file)
    }
  }
}
</script>

<style scoped>

</style>
