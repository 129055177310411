import Vue from 'vue'
import App from './App.vue'
import i18n from '../i18n/vueIN'
import router from './router'
import 'animate.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Emmiter from 'eventemitter2'


Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$event = new Emmiter()

new Vue({
  render: h => h(App),
  router,
  i18n,// 挂载
}).$mount('#app')
