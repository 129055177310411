import Vue from 'vue'
import VueRouter from 'vue-router'
// 组件通过export暴露接口，路由中导入组件
import MainPage from '../pages/MainPage'
import News from '../pages/News'
import Company from '../pages/Company'
import Jobs from '../pages/Jobs'
import Contact from '../pages/Contact'
import Admin from '../pages/Admin'
import Home from "@/pages/mobile/Home";
import {isMobile} from "@/utils/common";

// 导入 vue-router 依赖
Vue.use(VueRouter);

const includPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return includPush.call(this, location).catch(err => err)
}
const routes = [{
    path: '/',   // 跳转路径
    name: 'project',    // 名称
    component: MainPage  // 组件
},{
    path: '/news',   // 跳转路径
    name: 'news',    // 名称
    component: News  // 组件
},{
    path: '/company',   // 跳转路径
    name: 'company',    // 名称
    component: Company  // 组件
},{
    path: '/jobs',   // 跳转路径
    name: 'careers',    // 名称
    component: Jobs  // 组件
},{
    path: '/contact',   // 跳转路径
    name: 'contact',    // 名称
    component: Contact  // 组件
},{
    path: '/admin',   // 跳转路径
    name: '后台',    // 名称
    component: Admin  // 组件
}]
const mobileRoutes = [
    {
        path: '/',   // 跳转路径
        name: 'project',    // 名称
        component: Home  // 组件
    }
]
export default new VueRouter({
    routes: isMobile() ? mobileRoutes : routes
});
