<template>
  <div class="swiper" :id="swiperId">
    <div class="swiper-wrapper">
<!--      <div class="swiper-slide">-->
<!--        <project-info :show-more="isMore" :data="project"></project-info>-->
<!--      </div>-->
      <div
          :id="'temp-img-' + swiperId"
          class="swiper-slide temp-img"

      >
        <img :src="project.cover" />
      </div>

      <div   v-for="(item,idx) in project.opts"
             :key="'pro-opt-' + idx" class="swiper-slide opt-img" >
        <img v-if="item.image" :src="item.image" />
        <div v-else class="opt-text">{{ item.content }}</div>
      </div>
    </div>
    <mobile-project-info :data="project"></mobile-project-info>
  </div>
</template>

<script>
import Swiper from "@/components/imgSwiper/swiper-bundle.min";
import MobileProjectInfo from "@/components/mobile/MobileProjectInfo";

export default {
  name: "MobileImage",
  components: {MobileProjectInfo},
  props: ["project","swiperId"],
  data(){

  },
  mounted() {
    this.init();
  },
  methods:{
    init() {
      this.swiper = new Swiper("#" + this.swiperId, {
        freeMode: true,
        slidesPerView: "auto",
        // loop:true,
        // loopedSlides:1,
        grabCursor: true,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        // slideToClickedSlide: true,
      });
    },
  }
}
</script>

<style scoped>
.swiper img{
  width: 100%;
  height: auto;
}
</style>
