<template>
  <div class="concat_panel">
    <div class="panel_item">
      <div @mouseenter="(chooseItem = 0)">{{ $t('address') }}</div>
      <div @mouseenter="(chooseItem = 1)">{{ $t('telephone') }}</div>
      <div @mouseenter="(chooseItem = 2)">{{ $t('mailbox') }}</div>
      <div @mouseenter="(chooseItem = 3)">{{ $t('socialize') }}</div>
    </div>
    <div class="content" @mouseleave="(chooseItem = -1)">
      <div v-if="(chooseItem === 0)">
        四川省成都市武侯区吉泰五路88号香年广场T2
      </div>
      <div v-if="(chooseItem === 1)">
        028-65225091，13982250591
      </div>
      <div v-if="(chooseItem === 2)">
        bvdesign@foxmail.com
      </div>
      <div v-if="(chooseItem === 3)">
        wx:13982250591
      </div>
    </div>
    <div id="backBtn" class="back" :class="{'visibility' : showBack ? 'visible' : 'hidden'}" @click="backToTop">BACK TO TOP</div>
    <div style="width:400px;text-align: center;">
      <a href="http://beian.miit.gov.cn/" target="_blank" style="font-size: 12px; color: #aaa;text-decoration: none;" >蜀ICP备2023004582号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      chooseItem:-1,
      showBack:false,
    }
  },
  mounted(){
    document.addEventListener('scroll',()=>{
      this.showBack = this.checkShowBack();
    })
  },
  methods:{
    backToTop(){
      const opt = {
        top: 0,
        behavior:'smooth'
      }
      this.$el.parentElement.scrollTo(opt);
    },
    checkShowBack(){
      return document.getElementById('backBtn').getBoundingClientRect().top > document.body.clientHeight
    }
  }

}
</script>

<style scoped>
.concat_panel {
  width: 400px;
  margin: auto;
  height: 250px;
  padding-top: 150px;
}

.panel_item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 20px;
}

.content {
  width: 100%;
  height: 180px;
  line-height: 40px;
  text-align: center;
}
.panel_item div {
  cursor: pointer;
}

.back {
  top: 350px;
    position: sticky;
    text-align: center;
    width: 400px;
    height: 30px;
    user-select: none;
    cursor: pointer;

}
</style>
