<template>
  <div class="mobileProjectInfo">
    <div class="infoImage">
      <img :src="data.projectLogo" style="height: 40px;width: 40px">
    </div>
    <div class="infoContent">
      <div class="infoName">
        {{data.projectName}}
      </div>
      <div class="infoLocation">
        {{data.location}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileProjectInfo",
  props:['data'],
  mounted() {

  },
  methods:{

  }
}
</script>

<style scoped>
.mobileProjectInfo {
  padding: 10px 0 20px 0;
  display: flex;
}
.infoImage {
  margin-right: 10px;
}
.infoContent {

}
</style>
