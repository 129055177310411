<template>
  <div class="main_content">
    <div style="display: flex;">
      <div class="menu_name" style="margin-right: 50px;">CAREERS</div>
      <div class="careers_desc" v-html="common.jobInfo"></div>
    </div>
    <div class="conten_panel">
      <div class="careers_panel">
        <img width="45%" :src="common.leftImg" />
        <img width="45%" :src="common.rightImg" />
      </div>
    </div>
    <div class="menu_name_h2">OPEN JOBS</div>
    <div class="conten_panel">
      <div class="job">
        <div class="sort">
          <span class="option">
            <span class="optionHeader">地区</span>
            <select id="location" class="option_location">
              <option value="All">所有</option>
              <option
                value="Barcelona"
              >
                成都
              </option>
            </select>
          </span>
        </div>
        <div class="header item">
          <div class="job_title">岗位</div>
          <div class="location">地区</div>
          <div class="deadline">截止日期</div>
        </div>
        <div class="list">
          <div v-for="(item, i) in allData" :key="i" class="item">
            <div class="list_item" style="width: 100%" @click="()=>{i === activeIdx ? activeIdx = -1 : activeIdx = i }">
              <div class="job_title">{{ item.job }}</div>
              <div class="location">{{ item.lcoation }}</div>
              <div class="deadline">{{ item.deadline }}</div>
            </div>
            <div v-show="activeIdx === i" style="white-space: pre-wrap;" v-html="item.info"></div>
          </div>
        </div>
      </div>
    </div>
    <concat-panel></concat-panel>
  </div>
</template>

<script>
import { download } from '../api/api';
import ConcatPanel from '../components/ConcatPanel.vue';
export default {
  components: { ConcatPanel },
  name: "Jobs",
  data() {
    return {
      activeIdx: -1,
      allData:[],
      common:{},
      jobList: [
      ],
    };
  },
  mounted(){
    this.$event.on('langChange',(arg)=>{
      this.init();
    })
    this.init();
  },
  methods:{
    init(){
      download(`common_${this.$i18n.locale}.json`).then(res => {
        if (res.data.code !== 500) {
          this.common = res.data
        }
      })
      download(`job_${this.$i18n.locale}.json`).then(res => {
        if(res.data.code !== 500 ) {
          this.allData = res.data
          // this.changeData()
        }
      })
    },
  }
};
</script>

<style scoped >
.menu_name_h2 {
  font-size: 50px;
  line-height: 100px;
}
.conten_panel {
}
.header {
  font-weight: bold;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item {
  border-bottom: 1px solid #c4c4c4;
}
.list_item {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
}
.job_title {
  width: 50%;
}
.deadline {
  width: 30%;
}
.location {
  width: 20%;
}
.careers_desc {
  width: 55%;
}
.careers_panel {
  display: flex;
  justify-content: space-between;
  height: 500px;
}
.option {
  width: 120px;
  display: flex;
    justify-content: space-between;
}
.option_location {
  width: 80px;
    text-align: center;
    border: none;
    outline: none;
}
</style>
