<template>
  <div id="app">
    <transition
        name="animate__animated animate__zoom"
        leave-active-class="animate__zoomOutDown"
    >
      <div v-show="loading" key="loading">
        <full-loading></full-loading>
      </div>
    </transition>
    <Menus v-if="!isMobile"></Menus>
    <keep-alive>
      <router-view>
      </router-view>
    </keep-alive>
  </div>

</template>

<script>
import FullLoading from "./components/FullLoading.vue";
import Menus from "./components/Menus.vue";
import {isMobile} from "@/utils/common";

export default {
  name: "App",
  components: {
    Menus,
    FullLoading,
  },
  data() {
    return {
      imgs: [

      ],
      count: 0,
      loading: true,
    };
  },
  watch: {
    count(val) {
      if (this.imgs.length === val) {
        this.loading = false;
      }
    },
  },
  computed:{
    isMobile(){
      return isMobile();
    }
  },
  created() {
    this.loading = false;
    document.onreadystatechange = function () {
      if(document.readyState === 'complete') {
        console.log('加载完毕')
      }
    }
  },
  methods: {

  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  height: 100%;
  width: 100%;
  font-size: 14px;
  position: absolute;
}

.main_content {
  padding-left: 150px;
  padding-right: 50px;
  height: calc(100% - 70px);
  overflow-y: scroll;
  overflow-x: hidden;
}

/* .main_content::-webkit-scrollbar {
  width: 0px;
} */


</style>
