<template>
  <div id="pro_block" class="main_content" style="padding:0">
      <project-list :data="proList"></project-list>
      <!-- <concat-panel></concat-panel> -->
  </div>
</template>

<script>
import {download} from '../api/api';
import ConcatPanel from '../components/ConcatPanel.vue';
import ProjectList from '../components/ProjectList.vue';
import zh from "../../i18n/zh";
export default {
  components: {ProjectList  },
  data() {
    return {
      proList:[],
      allList:[]
    };
  },
  mounted(){
    this.$event.on('menuClick',(menu,subMenu)=>{
      this.projectChange(menu,subMenu)
    })

    this.$event.on('searchChange',(arg)=>{
      this.searchChange(arg)
    })
    this.$event.on('langChange',(arg)=>{
      this.init();
    })
    this.init();
  },
  methods: {
    init(){
      download(`project_${this.$i18n.locale}.json`).then(res => {
        this.allList = res.data.sort((a,b)=>{
          return a.beginDate > b.beginDate ? -1 : 1
        })
        this.proList = this.allList
      })
    },
    projectChange(type,subType){
      if(type) {
        this.proList = this.allList.filter(item => {
          return item.type[0] === type.label
        })
      }

      if(subType) {
        this.proList = this.proList.filter(item => {
          return item.type[1] === subType.label
        })
      }
    },
    searchChange(data) {
      if(!data) {
        this.proList = this.allList
        return
      }
      const translate = (data) => {
        return Object.keys(zh).find(key => {
          return zh[key] === data
        }) || data
      }
      console.log(this.allList,data)
      this.proList =  this.allList.filter(item => {
          return item.projectName.includes(data) || item.type.includes(translate(data))
        })
    },


  },
};
</script>

<style  scoped>
.main {
  width: 100%;
  height: 100%;
}


</style>
