<template>
  <div class="full_loading">
    <div class="logos animate__animated  animate__bounce animate__infinite">
      <img width="90px" src="@/assets/logo.png" />
      <div class="logo_name">BV</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullLoading",
};
</script>

<style scoped>
.full_loading {
  height: 100vh;
  width: 100vw;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logos {
  width: 90px;
  height: 115px;
  position: relative;
}
.logo_name {
  color: white;
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  right: 0px;
  bottom: 0;
}
</style>