<template>
  <div class="mobileHome">
    <div class="header">
      <div @click="menuDrawerVisible=!menuDrawerVisible">left</div>
      <div @click="typeDrawerVisible=!typeDrawerVisible">right</div>
    </div>
    <div>
      <div class="lists">
        <mobile-image  v-for="(item,i) in proList" :swiper-id="('pro1' + i)" :key="i"  :project="item"></mobile-image>
      </div>
      <!--  组件  -->
      <el-drawer
          :modal="false"
          size="30%"
          :visible.sync="menuDrawerVisible"
          direction="ltr"
          :withHeader="false"
          custom-class="menuDrawer"
      >
        <div>
          <left-menu :is-mobile="true"></left-menu>
        </div>
      </el-drawer>
      <el-drawer
          :modal="false"
          size="30%"
          :visible.sync="typeDrawerVisible"
          direction="rtl"
          :withHeader="false"
          custom-class="typeDrawer"
      >
        <div>
          抽屉
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {download} from "@/api/api";
import MobileImage from "@/components/imgSwiper/MobileImage";
import LeftMenu from "@/components/LeftMenu";

export default {
  name: "Home",
  components: {LeftMenu, MobileImage},
  data(){
    return {
      menuDrawerVisible:false,
      typeDrawerVisible:false,
      proList:[],
      allList:[]
    }
  },
  mounted() {
    this.init();
  },
  methods:{
    init(){
      download(`project_${this.$i18n.locale}.json`).then(res => {
        this.allList = res.data
        this.proList = res.data
      })
    },
    projectChange(type,subType){
      if(type) {
        this.proList = this.allList.filter(item => {
          return item.type[0] === type.label
        })
      }

      if(subType) {
        this.proList = this.proList.filter(item => {
          return item.type[1] === subType.label
        })
      }
    },
    searchChange(data) {
      if(!data) {
        this.proList = this.allList
        return
      }
      this.proList =  this.allList.filter(item => {
        return item.projectName.includes(data)
      })
    },

  }
}
</script>

<style scoped>
.mobileHome {
  height: 100vh;
  width: 100vw;
}
.header {
  padding: 0 20px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lists {
  width: calc(100% - 30%);
  padding: 0 15%;
  height: calc(100vh - 40px);
  overflow-y: scroll;

}

</style>
<style>
.menuDrawer , .typeDrawer{
  box-shadow:none !important;
  top: 40px !important;
  height: calc(100% - 40px);
}
</style>
