<template>
  <div class="admin_content">
    <div style="padding:20px;">
      <span style="margin-right:10px">语音选择</span>
      <el-radio-group v-model="lang" @input="langChange">
        <el-radio-button label="zh">中文</el-radio-button>
        <el-radio-button label="en">英文</el-radio-button>
      </el-radio-group>
   </div>
    <el-tabs tab-position="left" style="height: 90vh; margin-top: 30px; overflow-y: scroll">
      <el-tab-pane label="新增项目">
<!--        <el-button @click="edit" type="primary" size="small"-->
<!--          style="margin-bottom: 20px">编辑项目</el-button><span>(先输入项目名称，获取对应信息后再进行修改)</span>-->
<!--        <el-form ref="proForm" :inline="true" :mode="project" label-width="80px" style="width: 80vw">-->
<!--          <el-form-item label="项目名称">-->
<!--            <el-input v-model="project.projectName"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="位置">-->
<!--            <el-input v-model="project.location"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="开始年份">-->
<!--            <el-input v-model="project.beginDate"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="客户">-->
<!--            <el-input v-model="project.client"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="面积">-->
<!--            <el-input v-model="project.size"> </el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="状态">-->
<!--            <el-input v-model="project.status"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="分类">-->
<!--            <el-cascader v-model="project.type" :options="typeOptions" :props="{ expandTrigger: 'hover' }"></el-cascader>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="图标">-->
<!--            <el-upload class="upload-demo" action="https://www.bv-design.cn/api/upload" list-type="picture"-->
<!--              :on-success="logoUpload">-->
<!--              <el-button size="small" type="primary">点击上传</el-button>-->
<!--            </el-upload>-->
<!--          </el-form-item>-->

<!--          <el-form-item label="效果图">-->
<!--            <el-upload class="upload-demo" action="#" list-type="picture" :on-success="coverUpload" :multiple="true"-->
<!--              :http-request="fileChange" ref="batchUpload">-->
<!--              <el-button size="small" type="primary">点击上传</el-button>-->
<!--            </el-upload>-->
<!--          </el-form-item>-->
<!--          <el-form-item>-->
<!--            <el-button @click="save" type="primary" :loading="loading">提交</el-button>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
        <project-table :lang="lang"></project-table>
      </el-tab-pane>
      <el-tab-pane label="新闻编辑">
        <news-table :lang="lang"></news-table>
      </el-tab-pane>
      <el-tab-pane label="就业编辑">
        <job-table :lang="lang"></job-table>
      </el-tab-pane>
      <el-tab-pane label="团队核心">
        <h2>合伙人</h2>
        <partner-table :lang="lang"></partner-table>
        <h2>设计团队</h2>
        <team-table :lang="lang"></team-table>
      </el-tab-pane>
      <el-tab-pane label="其他信息">
        <common-form :lang="lang"></common-form>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script>
import {getImgUrl, download, upload} from "../api/api";
import NewsTable from "@/components/admin/news/NewsTable";
import JobTable from "../components/admin/job/JobTable.vue";
import CommonForm from "@/components/admin/CommonForm";
import PartnerTable from "@/components/admin/company/partner/PartnerTable";
import TeamTable from "@/components/admin/company/team/TeamTable";
import ProjectTable from "@/components/admin/project/ProjectTable";
export default {
  components: {ProjectTable, TeamTable, PartnerTable, CommonForm, NewsTable, JobTable },
  data() {
    return {
      project: {
        projectName: "",
        projectLogo: "",
        location: "",
        beginDate: "2023",
        client: "",
        size: "",
        status: "",
        type: "",
        cover: "",
        opts: [],
      },
      typeOptions: [
        {
          label: "建筑",
          value: "architecture",
          children: [
            {
              label: "商业",
              vaule: "business"
            },
            {
              label: "酒店",
              vaule: "hotel"
            },
            {
              label: "教育",
              vaule: "educate"
            },
            {
              label: "文化",
              vaule: "culture"
            },
            {
              label: "办公",
              vaule: "office"
            }, {
              label: "自宅",
              vaule: "house"
            }, {
              label: "艺术",
              vaule: "art"
            },{
              label: "公共",
              vaule: "public"
            },
          ],
        },
        {
          label: "室内",
          value: "indoor",
          children: [
            {
              label: "商业",
              vaule: "business"
            },
            {
              label: "酒店",
              vaule: "hotel"
            },
            {
              label: "教育",
              vaule: "educate"
            },
            {
              label: "文化",
              vaule: "culture"
            },
            {
              label: "办公",
              vaule: "office"
            }, {
              label: "自宅",
              vaule: "house"
            }, {
              label: "艺术",
              vaule: "art"
            },
          ],
        },
        {
          label: "景观",
          value: "景观",
          children: [],
        },
        {
          label: "规划",
          value: "规划",
        },
        {
          label: "未来",
          value: "未来",
        },
      ],
      coverfileList: [],
      projectList: [],
      loading: false,
      lang:'zh',
      type:'project'
    };
  },
  computed:{
    fileName(){
      return `${this.type}_${this.lang}.json`
    }
  },
  mounted() {
  },
  methods: {
    getData(){
      download(this.fileName).then((res) => {
        if(!res.data.code) {
          this.projectList = res.data;
        } else{
          this.projectList = []
        }

    });
    },
    langChange(val){
      this.getData()
    },
    edit() {
      if(this.projectList.length === 0) {
        this.$message.error('没有该项目')
        return ;
      }
      this.project = this.projectList.find((item, index) => {
        if (item.projectName === this.project.projectName) return item
      })
    },
    save() {
      this.loading = true
      let oldIndex = -1
      this.projectList.forEach((item, index) => {
        if (item.projectName === this.project.projectName) {
          oldIndex = index;
        }
      })
      if (oldIndex === -1) {
        this.projectList.push(this.project);
      }

      const file = new File([JSON.stringify(this.projectList)], this.fileName);
      upload(file).then(res => {
        this.$refs.proForm.resetFields();
        this.loading = false;
      });
    },
    coverUpload(res, file) {
      this.project.cover = getImgUrl(file);
    },
    logoUpload(res, file) {
      this.project.projectLogo = getImgUrl(file);
    },
    optUpload(file, opt) {
      opt.image = getImgUrl(file);
    },

    fileChange(req) {
      upload(req.file).then((res) => {
        if (this.project.opts.length === 0 && !this.project.cover) {
          this.project.cover = getImgUrl(req.file);
        } else {
          this.project.opts.push({
            image: getImgUrl(req.file),
          });
        }
      });
    },
  },
};
</script>

<style>
.admin_content {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2000;
  background-color: white;
}

.upload-demo {
  display: inline-block;
  margin-right: 20px;
}

.action_group {
  position: fixed;
  right: 0;
  top: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 200px;
}

.action_group button {
  margin-right: 20px;
  margin-left: 0px !important;
}
</style>
