// const host = "http://localhost:3000"
const host = "https://www.bv-design.cn/api"

export function upload(file) {
  const data = new FormData()
  data.set('file',file)
  return axios.post(host + '/upload',data,{
    header:{
      'Content-Type':'mulipart/form-data'
    }
  })
}

export function download (fileName) {
  return axios.get(host + "/download/" + fileName)
}


export function getImgUrl(file) {
  return host + "/download/" + file.name;
}
