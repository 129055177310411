<template>
  <div style="height: 100%;width: 100%;overflow-y: scroll">
    <div style="width: 100%;height: 100%">
      <img style="width: 100%;height: 100%; object-fit: cover;" :src="allData.bigImg">
    </div>
    <div class="panel">
      <div class="panel_left">
        <div class="menu_name" id="intro">BV</div>
        <div style="padding-left: 70px;" v-html="allData.companyInfo"></div>
      </div>
      <div class="panel_right">
        <img style="width: 100%;height: 100%; object-fit: cover;" :src="allData.infoImg">
      </div>
    </div>
    <div class="people-panel">
      <div class="menu_name">PEOPLE</div>
      <h2 id="team">核心成员</h2>
      <div  v-for="(partner) in partners" :key="partner.id" style="display: flex;margin-bottom: 50px;padding-left: 70px;">
        <div style="width: 200px;height: 250px;margin-right: 50px">
          <img :src="partner.headImg" width="100%" height="100%">
        </div>
        <div v-html="partner.content">
        </div>
      </div>
<!--      <h2 >设计团队</h2>-->
<!--      <div style="display: grid;padding-left: 70px;-->
<!--    grid-template-columns: 200px 200px 200px;-->
<!--    grid-column-gap: 50px;-->
<!--grid-row-gap: 30px">-->
<!--        <div v-for="team in teams" :key="team.id" >-->
<!--          <div style="width: 200px;height: 250px;margin-right: 50px">-->
<!--            <img :src="team.headImg" width="100%" height="100%">-->
<!--          </div>-->
<!--          <div style="line-height: 20px">{{team.name}}</div>-->
<!--          <div style="line-height: 20px">{{team.date}}</div>-->
<!--        </div>-->
<!--      </div>-->
      <h2 id="award">奖项</h2>
      <div class="news_list">
      <div class="news_item" v-for="(item) in jxData" :key="item.id">
        <div class="timeline">{{item.date}}</div>
        <img width="400px"
          style="margin-right: 10px; margin-left: 10px"
          :src="item.cover"/>
        <div class="desc">
          <div class="title">{{item.title}}</div>
          <div class="text" v-html="item.content">
          </div>
        </div>
      </div>
    </div>
    </div>
    <div style="height: 200px;width: 100%"></div>

    <sub-menus :menu-list="menuList" :active-menu="activeMenu" @change="menuClick"></sub-menus>
  </div>
</template>

<script>
import ConcatPanel from '../components/ConcatPanel.vue'
import PeoplePanel from '../components/PeoplePanel.vue'
import {download} from "@/api/api";
import SubMenus from "@/components/SubMenus";

export default {
  // components: { PeoplePanel, ConcatPanel },
  name: 'Company',
  components: {SubMenus},
  data() {
    return {
      chooseItem: {},
      allData: {},
      partners: [],
      teams:[],
      menuList:[
        {
          label:'intro'
        },
        {
          label:'team'
        },{
          label:'award'
        }
      ],
      activeMenu:{
        label:'intro'
      },
      jxData:[]
    }
  },
  mounted() {
    this.$event.on('langChange', (arg) => {
      this.init();
    })
    this.init();
  },
  methods: {
    init() {
      download(`common_${this.$i18n.locale}.json`).then(res => {
        if (res.data.code !== 500) {
          this.allData = res.data
        }
      })
      download(`partner_${this.$i18n.locale}.json`).then(res => {
        if (res.data.code !== 500) {
          this.partners = res.data
        }
      })
      // download(`team_${this.$i18n.locale}.json`).then(res => {
      //   if (res.data.code !== 500) {
      //     this.teams = res.data
      //   }
      // })
      download(`news_${this.$i18n.locale}.json`).then(res => {
        if(res.data.code !== 500 ) {
          this.jxData = res.data.filter(item => item.type.includes('award'))
        }
      })
    },
    menuClick(val){
      this.activeMenu = val;
      document.getElementById(val.label).scrollIntoView({
        block:'center',
        behavior:'smooth'
      })
    }
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  display: none
}

.panel {
  padding-left: 150px;
  padding-right: 30px;
  width: calc(100% - 100px);
  display: flex;
}

.panel_left {
  width: 40%;
}

.panel_right {

}

.news_list {
  padding-left: 70px;
  padding-right: 30px;
  width: calc(100% - 250px);
}

.desc {
  width: 80%;
}
.people-panel {
  padding-left: 150px;
  padding-right: 30px;
  width: calc(100% - 100px);
}
</style>
